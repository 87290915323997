import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import CloseRounded from "@mui/icons-material/CloseRounded";
import IconButton from "@mui/material/IconButton";

const DialogV1 = ({
  open,
  onClose: handleClose,
  title,
  children,
  maxWidth = "sm",
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    maxWidth={maxWidth}
    fullWidth
    sx={{
      ".MuiDialog-container": {
        alignItems: "flex-start",
      },
      "& .MuiDialog-paper": {
        marginTop: 0,
        borderRadius: 2,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    }}
  >
    <DialogTitle
      sx={{
        backgroundColor: "grey.100",
        position: "relative",
        padding: 0,
        paddingLeft: 3,
        paddingTop: 1,
        paddingBottom: 1,
        fontWeight: "bold",
      }}
    >
      {title}
      <IconButton
        onClick={handleClose}
        color="dark"
        size="small"
        variant="text"
        sx={{ position: "absolute", right: 8, top: 8 }}
      >
        <CloseRounded />
      </IconButton>
    </DialogTitle>
    <Divider sx={{ margin: 0 }} />
    {children}
  </Dialog>
);

export default DialogV1;
