// React and Redux imports
import { useSelector } from "react-redux";

// Redux selectors
import { selectAppDialog } from "redux/app/app.selectors";

// MUI components
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Stack from "@mui/material/Stack";

// Custom components
import SoftTypography from "myComponents/SoftTypography";
import SoftButton from "myComponents/SoftButton";
import DialogV1 from "components/CustomDialog/v1";

const StudentExistsAlert = ({ open, handleClose }) => {
  const { dialogData } = useSelector(selectAppDialog);
  const { info, manualApplicationNo } = dialogData;

  return (
    <DialogV1
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      title="Student Exists"
    >
      <DialogContent>
        <SoftTypography variant="h6" fontWeight="medium">
          Application No (<b>{manualApplicationNo}</b>)&nbsp;
          <b style={{ color: "red" }}>Already Exists</b>
        </SoftTypography>
        <Stack spacing={1} mt={2} mb={1}>
          <SoftTypography variant="body2">
            <b>Name :</b> {info.name || "N/A"}
          </SoftTypography>
          <SoftTypography variant="body2">
            <b>Admission No : </b> {info.admissionNo || "N/A"}
          </SoftTypography>
          <SoftTypography variant="body2">
            <b>Application No : </b> {info.applicationNo || "N/A"}
          </SoftTypography>
          <SoftTypography variant="body2">
            <b>Class : </b> {info.class || "N/A"}
          </SoftTypography>
          <SoftTypography variant="body2">
            <b>Father&apos;s Name : </b> {info.fatherName || "N/A"}
          </SoftTypography>
          <SoftTypography variant="body2">
            <b>Father&apos;s Number : </b> {info.fatherNumber || "N/A"}
          </SoftTypography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <SoftButton onClick={handleClose} color="info" size="small">
          Close
        </SoftButton>
      </DialogActions>
    </DialogV1>
  );
};

export default StudentExistsAlert;
