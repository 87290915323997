// MUI colors
import green from "@mui/material/colors/green";
import orange from "@mui/material/colors/orange";
import purple from "@mui/material/colors/purple";
import red from "@mui/material/colors/red";
import blue from "@mui/material/colors/blue";

// Assets and theme
import icici from "assets/svgs/icici.svg";
import hdfc from "assets/svgs/hdfc.svg";
import cashfree from "assets/svgs/cashfree.svg";
import onePay from "assets/svgs/1pay.svg";

import SoftTypography from "myComponents/SoftTypography";
// formatter
import { formatNumber } from "utils/INRformatter";

export default [
  {
    field: "index",
    headerName: "S No",
    editable: false,
    width: 40,

    valueGetter: (params) => {
      const ids = params.api.getAllRowIds();

      return ids.indexOf(params.id) + 1;
    },
  },
  {
    field: "feeType",
    headerName: "Fee Type",
    editable: false,
    minWidth: 100,
    flex: 1,
    align: "left",
    renderCell: (params) => (
      <SoftTypography variant="body2" fontWeight="bold" color="secondary">
        {params.row?.feeType}
      </SoftTypography>
    ),
  },
  {
    field: "payingAmount",
    headerName: "Amount Paying",
    editable: false,
    minWidth: 100,
    flex: 1,
    align: "center",
    renderCell: (params) => (
      <SoftTypography variant="body2" fontWeight="bold" color="dark">
        {formatNumber(params.row?.payingAmount)}
      </SoftTypography>
    ),
  },
  {
    field: "exclusiveGST",
    headerName: "GST exclusive ",
    editable: false,
    minWidth: 80,
    flex: 1,
    align: "center",
    renderCell: (params) => (
      <SoftTypography variant="body2" fontWeight="bold" color="secondary">
        {formatNumber(params.row?.exclusiveGST)}
      </SoftTypography>
    ),
  },
  {
    field: "includingGST",
    headerName: "GST Inclusive",
    editable: false,
    minWidth: 80,
    flex: 1,
    align: "center",
    renderCell: (params) => (
      <SoftTypography variant="body2" fontWeight="bold" color="secondary">
        {formatNumber(params.row?.includingGST)}
      </SoftTypography>
    ),
  },
];

const paymentGateways = [
  {
    id: "icici",
    name: "ICICI",
    logo: icici,
    description: "ICICI is a secure and fast payment gateway.",
  },
  {
    id: "1pay",
    name: "1Pay",
    logo: onePay,
    bg: "blue",
    poweredBy: hdfc,
    description: "1Pay is a secure and fast payment gateway.",
  },
  {
    id: "cashfree",
    name: "Cashfree",
    logo: cashfree,
    description: "Cashfree is a secure and fast payment gateway.",
  },
];

const offCash = { mode: "CA", label: "Cash", icon: "money", color: green[500] };
const offCC = {
  mode: "CC",
  label: "Credit Card",
  icon: "credit_card",
  color: purple[500],
};
const offNB = {
  mode: "NB",
  label: "Net Banking",
  icon: "language",
  color: blue[500],
};
const offSWIPE = {
  mode: "SWIPE",
  label: "Swipe",
  icon: "credit_card",
  color: purple[500],
};
const offDC = {
  mode: "DC",
  label: "Debit Card",
  icon: "credit_card",
  color: red[500],
};
const offCQ = {
  mode: "CQ",
  label: "Cheque",
  icon: "account_balance",
  color: orange[500],
};

const meansLabels = {
  DIRECT_ENTRY: "Direct Entry",
  PAYMENT_GATEWAY: "Payment Gateway",
  POS: "POS",
};

export const checkoutUtilsData = {
  paymentGateways,
  offCash,
  offCC,
  offNB,
  offSWIPE,
  offDC,
  offCQ,
  meansLabels,
};
