import store from "store";
import baseAxios from "../baseAxios";

const COMMON = "/finance/fee-structure";

export async function createFeePlan(body) {
  try {
    return (await baseAxios.post(COMMON, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchFeePlans(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (
      await baseAxios.get(path, {
        params: { ...rest, instituteId: store.get("instituteId") },
      })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateFeePlan(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.patch(`${COMMON}/${_id}`, { ...rest })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function assignFeePlans(obj) {
  try {
    return (await baseAxios.patch(`${COMMON}/assign`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function resetReservationFee(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/reset-res-fee`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function createReservationFee(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/res`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchReservationFees(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/res`, { params: obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateReservationFee({ id, data }) {
  try {
    return (await baseAxios.patch(`${COMMON}/res/${id}`, data)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createApplicationNoRange(obj) {
  try {
    return (await baseAxios.post(`/finance/app_no_range`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchApplicationNoRange(obj) {
  try {
    return (
      await baseAxios.get(
        `/finance/app_no_range?academicYear=${obj?.academicYear || ""}`
      )
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateApplicationNoRange(obj) {
  const { _id, ...rest } = obj;
  try {
    return (await baseAxios.patch(`/finance/app_no_range/${_id}`, rest)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function isPartialAllowedInResFee(params) {
  try {
    return (await baseAxios.get(`${COMMON}/res/check`, { params })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function assignAdditionalFee({ body }) {
  try {
    return (await baseAxios.post(`${COMMON}/add_fee_assign`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentFeeTxnDue(obj) {
  try {
    return (await baseAxios.post(`/finance/fee/txnDueSummary`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchStudentFeeSummary(obj) {
  try {
    const { paidMap, ...rest } = obj;
    if (obj.paidMap) {
      return (
        await baseAxios.post(`/finance/fee/summary?allPaidMap=true`, rest)
      ).data;
    }
    return (await baseAxios.post(`/finance/fee/summary`, obj)).data;
  } catch (error) {
    if (error.message.includes("PLAN_NOT_ASSIGNED AND CUSTOM_FEE_NOT_FOUND")) {
      const a = {
        message: "Fee plan missing for selected student. Please verify.",
        result: {
          message: "Fee plan missing for selected student. Please verify.",
        },
      };
      return Promise.reject(a);
    }
    return Promise.reject(error);
  }
}
export async function getFeeCollectionsReport(body) {
  try {
    return (await baseAxios.post(`/finance/fee/fee-collections-report`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaidAndDueReports(body) {
  try {
    return (await baseAxios.post(`/finance/fee/paid-and-due-report`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function downloadPaidAndDueReports(body) {
  try {
    return (
      await baseAxios.post(`/finance/fee/download-paid-and-due-report`, body)
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeeDuesReport(body) {
  try {
    return (await baseAxios.post(`/finance/fee/fee-dues`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function downloadAllFeeDues(body) {
  try {
    return (await baseAxios.post(`/finance/fee/download-fee-dues`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeeDetails(body) {
  try {
    return (await baseAxios.post(`/finance/fee/fee-details`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBranchTransferInputs(body) {
  try {
    return (await baseAxios.post(`/finance/fee/bt-inputs`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postCautionDepositAdjustments(body) {
  try {
    return (await baseAxios.post(`/finance/fee/cd-withdral-or-refund`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCautionDepositInputs(body) {
  try {
    return (await baseAxios.get(`/finance/fee/cd-inputs`, { params: body }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getTxnList(body) {
  try {
    return (await baseAxios.post(`/finance/txn/txn-list`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBatchTransferInputs(body) {
  try {
    return (await baseAxios.post(`/finance/fee/bt-batch-inputs`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getGeneralFeePlan(body) {
  try {
    return (await baseAxios.post(`/finance/fee/gen-fee`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAllAcademicYearDues(body) {
  try {
    return (await baseAxios.post(`/finance/fee/all-ay-dues`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getPaidAndDueReportOfBranch(body) {
  try {
    return (await baseAxios.post(`/finance/fee/p&d-report`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getInstallemstReportOfBranch(body) {
  try {
    return (await baseAxios.post(`/finance/fee/ins-report`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getPaidAndDueReportOfBranchBatchWise(body) {
  try {
    return (await baseAxios.post(`/finance/fee/p&d-report-batch-wise`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getFreeStrucutre(body) {
  try {
    return (await baseAxios.post(`/finance/fee/get-fee-structure`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getFeeCollectionsAndExpensesReport(body) {
  try {
    return (await baseAxios.post(`/finance/fee/collections&expenses`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBranchFeeLedgerReport(body) {
  try {
    return (await baseAxios.post(`/finance/fee/branch-fee-ledger`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getStudentStatement(body) {
  try {
    return (await baseAxios.post(`/finance/fee/stu-acc-statement`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchConcessionReports(body) {
  try {
    return (await baseAxios.post(`/finance/fee/concession-report`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchConcessionReportBatchWise(body) {
  try {
    return (
      await baseAxios.post(`/finance/fee/concession-report-batch-wise`, body)
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getConcessApprovalHistory(body) {
  try {
    return (
      await baseAxios.post(`/finance/fee/concession-approval-history`, body)
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCollectionsDateWiseWithConcession(body) {
  try {
    return (await baseAxios.post(`/finance/fee/fee-coll-with-concession`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getStudentConcessionDetails(body) {
  console.log(body);
  try {
    return (await baseAxios.post(`/finance/fee/concession-details`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getTodaysCollection(body) {
  try {
    return (await baseAxios.post(`/finance/fee/today-collection`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function getBatchesAndBranchOfFeeType(body) {
  try {
    return (await baseAxios.post(`/finance/fee/fee-type-b&b`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getCollectionsAndPosTerminalsMap() {
  try {
    return (await baseAxios.get(`/finance/pos-terminals/map`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getTransactionSummary(body) {
  try {
    return (await baseAxios.post(`/finance/txn/gen-summary`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function makeDynamicFeeAdjustMent(body) {
  try {
    return (await baseAxios.post(`/finance/txn/dynamic-fee-adjustment`, body))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function initPayment(body) {
  try {
    return (await baseAxios.post(`/finance/txn/make-payment-v1`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function postPgRedirect(body) {
  try {
    return (await baseAxios.post(`/finance/txn/pg-redirect`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPosTxnStatus(body) {
  try {
    return (await baseAxios.post(`/finance/txn/pos-txn-status`, body)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
