import { useState, useEffect, useRef } from "react";

export default function useFetch(APIMethod = Promise, pollingInterval = null) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [pollCount, setPollCount] = useState(0);
  const intervalRef = useRef(null);

  const fetchData = async (
    payload,
    shouldPoll = false,
    stopCondition = null,
    maxRetries = Infinity
  ) => {
    try {
      setIsLoading(true);
      const response = await APIMethod(payload);
      setData(response);
      console.log("🚀 ~ useFetch ~ response:", response);

      // Stop polling if stop condition is met
      if (stopCondition && stopCondition(response)) {
        clearTimeout(intervalRef.current);
        intervalRef.current = null;
        return;
      }

      // Stop polling if max retries are reached
      if (pollCount + 1 >= maxRetries) {
        clearTimeout(intervalRef.current);
        intervalRef.current = null;
        return;
      }

      // Continue polling if shouldPoll is true and pollingInterval is set
      if (shouldPoll && pollingInterval) {
        setPollCount((prev) => prev + 1); // Increment poll count
        intervalRef.current = setTimeout(() => {
          fetchData(payload, true, stopCondition, maxRetries);
        }, pollingInterval);
      }
    } catch (err) {
      setError(err?.message);

      // Stop polling on error
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
        intervalRef.current = null;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetFetch = () => {
    setData(null);
    setError(null);
    setIsLoading(false);
    setPollCount(0);
    if (intervalRef.current) {
      clearTimeout(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // Cleanup on unmount
  useEffect(
    () => () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
    },
    []
  );

  return [isLoading, error, data, fetchData, resetFetch, pollCount];
}
