import { createSelector } from "@reduxjs/toolkit";

export const selectMakePayment = (state) => state.makePayment;

export const selectMakePaymentParams = createSelector(
  [selectMakePayment],
  (s) => s.data
);

export const selectStudentToMakeTxn = createSelector(
  [selectMakePayment],
  (s) => s.student
);

export const selectOnlineTxnReqInfo = createSelector(
  [selectMakePayment],
  (s) => s.onlineTxnReqInfo
);

export const selectCustomer = createSelector(
  [selectMakePayment],
  (s) => s.customer
);

export const selectFeeReports = createSelector(
  [selectMakePayment],
  (s) => s.reports
);

export const selectFeeTypes = createSelector(
  [selectMakePayment],
  (s) => s.feeTypes
);

export const selectPosTerminals = createSelector(
  [selectMakePayment],
  (s) => s.posTerminals
);

export const getBranchPosTerminalsSelector = (branch) =>
  createSelector(
    [selectPosTerminals],
    (posTerminals) => posTerminals?.[branch] || []
  );
